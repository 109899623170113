<template>
  <div id="profile">
    <v-container grid-list-xl fluid>
      <header-title title="Perfil de usuario"></header-title>
      <!-- <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click="showDialog()">
          <v-icon>mdi-plus</v-icon>Nuevo
        </v-btn>
      </v-card-title>-->
      <v-form ref="form">
        <v-card>
          <v-card-text>
            <span class="text--darken-8 grey--text">
              Los campos con
              <b class="red--text">*</b> son requeridos.
            </span>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    type="text"
                    label="Nombres *"
                    v-model="user.persona.nombres"
                    :rules="[v => !!v || 'El campo es requerido']"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    type="text"
                    label="Paterno"
                    v-model="user.persona.paterno"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    type="text"
                    label="Materno"
                    v-model="user.persona.materno"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    type="text"
                    label="Cédula identidad *"
                    v-model="user.persona.cedula_dni"
                    :rules="[v => !!v || 'El campo es requerido']"
                    autocomplete="off"
                    readonly
                  ></v-text-field>
                </v-flex>
                <!-- <v-flex xs6>
                  <v-select
                    label="Expedido *"
                    :items="expedidos"
                    v-model="user.expedido"
                    item-text="descripcion"
                    item-value="id"
                    :rules="[(v) => !!v || 'El campo es requerido']"
                    required
                  ></v-select>
                </v-flex> -->
                <v-flex xs6>
                  <v-text-field
                    type="text"
                    label="Correo electrónico *"
                    v-model="user.correo"
                    :rules="[v => !!v || 'El campo es requerido']"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-switch
                    v-model="user.cambia_clave"
                    :label="
                      user.cambia_clave
                        ? 'Cambiar contraseña? Si'
                        : 'Cambiar contraseña? No'
                    "
                  >
                  </v-switch>
                  <v-text-field
                    type="password"
                    label="Contraseña *"
                    v-model="user.clave"
                    :rules="[
                      user.cambia_clave
                        ? (v => !!v || 'El campo es requerido',
                          v => (!!v && v.length > 4) || 'Mínimo de 5 dígitos')
                        : true,
                    ]"
                    :disabled="!user.cambia_clave"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <p class="text-center">
              <v-btn color="primary" @click.native="updateProfile()">
                <v-icon>mdi-refresh</v-icon>Actualizar
              </v-btn>
            </p>
          </v-card-text>
        </v-card>
      </v-form>
    </v-container>
  </div>
</template>
<script>
  /* eslint-disable no-console */
  import HeaderTitle from "@/components/HeaderTitle";
  import Service from "../utils/general";
  import { mapActions } from "vuex";
  import axios from "axios";
  export default {
    name: "profile",
    components: {
      HeaderTitle,
    },
    data() {
      return {
        user: {
          id: "",
          persona: {
            nombres: "",
            paterno: "",
            materno: "",
            cedula_dni: "",
          },
          correo: "",
          clave: "",
          cambia_clave: false,
        },
      };
    },
    mounted() {
      if (Service.getUser() != null) {
        this.getUser(Service.getUser().uid);
      }
    },
    methods: {
      ...mapActions(["toast"]),
      getUser(uid) {
        axios
          .get(Service.getServe() + "usuario/" + uid, Service.getHeader())
          .then(response => {
            this.user = response.data;
          })
          .catch(error => {
            console.error("Error al cargar registros", error);
          });
      },
      updateProfile() {
        if (this.$refs.form.validate()) {
          axios
            .put(
              Service.getServe() + "usuario/profile/" + this.user.id,
              this.user,
              Service.getHeader()
            )
            .then(() => {
              this.toast({ color: "success", text: "Registro actualizado" });
              if (Service.getUser() != null) {
                this.getUser(Service.getUser().uid);
              }
            })
            .catch(() => {
              this.toast({ color: "error", text: "No se pudo actualizar" });
              console.error("Error al actualizar");
            });
        }
      },
    },
  };
</script>
